import { memo } from 'react';

import { Typography, Button } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';


import useBannerContratacao from './useBannerContratacao';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const BannerContratacao = () => {
  const { gerais } = useConfiguracoes();
  const { handleAbrirPlanosDialog } = useBannerContratacao();

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorPalette.yellow[300],
        gap: '12px',
      }}
    >
      <Typography variant='titleXS' color={colorPalette.neutral[0]}>
        Você está em teste grátis.
      </Typography>
      {Boolean(gerais?.ultValidadeProposta) && (
        <Button
          size='small'
          color='success'
          onClick={() => {
            handleAbrirPlanosDialog();
          }}
        >
          <Typography variant='titleXS' color={colorPalette.neutral[0]}>
            Quero contratar
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default memo(BannerContratacao);
