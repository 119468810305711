import { Button, Grid, Typography, LIcon, Dialog } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { colorPalette } from '@s_mart/tokens';
import { lineShareAlt } from '@s_mart/regular-icons';

import { TooltipContainer } from './boletoPronto.styles';
import { useBoletoPronto } from './useBoletoPronto';
import usePlanosDialog from 'shared/modais/planosDialog/store/planosDialog.store';
import { useInformaFaturaDialog } from 'shared/modais/informaFaturaDialog/informaFaturaDialog.store';

const BoletoPronto = () => {
  const { handleCopiarLinhaDigitavel, handleImprimirBoleto, isError, fatura } = useBoletoPronto();
  const { setBoletoProntoDialog } = usePlanosDialog();
  const useInformarFatura = useInformaFaturaDialog();

  const _telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <Dialog
      onClose={() => {
        setBoletoProntoDialog({ aberto: false });
        useInformarFatura.setIsOpen(true);
      }}
      open
      disableBackdropClick
      disableEscapeKeyDown
      closeIcon
    >
      <Dialog.Header>Plano contratado com sucesso!</Dialog.Header>
      <Dialog.Content>
        <Grid container style={{ gap: 24 }}>
          {isError ? (
            <>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 16 }}>
                  <TooltipContainer>
                    <div className='leftArrow' />
                    Seu plano foi contratado com sucesso! Logo seu boleto será processado e estará disponível para
                    pagamento.
                  </TooltipContainer>
                  <img src='/static/svgs/onBoarding/onBoardingBro.svg' alt='Bro indicando' />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid container style={{ gap: 8 }}>
                  <Grid item xs={12} style={{ marginBottom: 16 }}>
                    <Typography variant='bodySM'>Seu boleto já está disponível para pagamento!</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        border: '1px solid rgb(202, 202, 202)',
                        borderRadius: '8px',
                        padding: '0.5rem',
                      }}
                    >
                      {fatura.linhaDigitavel}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  style={{ padding: !_telaMenorQue600 ? '0px 32px' : 0, gap: !_telaMenorQue600 ? 16 : 8 }}
                >
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Button
                      fullWidth
                      onClick={handleCopiarLinhaDigitavel}
                      style={{ backgroundColor: colorPalette.green[300] }}
                    >
                      Copiar linha digitável
                    </Button>
                  </div>
                  {!_telaMenorQue600 ? (
                    <div style={{ display: 'flex', flex: 1 }}>
                      <Button
                        fullWidth
                        onClick={handleImprimirBoleto}
                        style={{ backgroundColor: colorPalette.green[300] }}
                      >
                        Imprimir meu boleto
                      </Button>
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <Button variant='outlined' color='success' onClick={handleImprimirBoleto}>
                        <LIcon icon={lineShareAlt} size='18px' />
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='bodySM'>
                  <span style={{ color: colorPalette.red[300] }}>*</span> Suas faturas serão enviadas para o e-mail
                  cadastrado.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Dialog.Content>

      <Dialog.Footer>{null}</Dialog.Footer>
    </Dialog>
  );
};

export { BoletoPronto };
