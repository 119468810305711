import { useCallback, useEffect } from 'react';

import { buscarDadosEmpresa, buscarRecursosPlano, consultarCobranca } from 'api/meusDados/meusDados';
import { buscarCodigoVendedor } from 'api/vendedores/vendedores';

import useConfiguracoes from 'store/configuracoes/configuracoes';
import useAuth from 'store/auth/auth';
import useApp from 'store/app/app';

import useOnBoarding from 'shared/modais/onBoarding/store/onBoarding.store';
import { useIsTesteGratis } from 'hooks/useIsTesteGratis/useIsTesteGratis';

const usePrivate = () => {
  const { profile } = useAuth();
  const { handleError, setLoading } = useApp();
  const { setMeusDados, setRecursosContratados, setInformacoesPlano, setVendedorAtual, setDadosCobranca } =
    useConfiguracoes();
  const isTesteGratis = useIsTesteGratis();
  const { gerais, atualizarConfiguracoesGerais } = useConfiguracoes();
  const { setOnBoardingBoasVindas, setOnBoardingFinalizado } = useOnBoarding();

  const handleOnBoarding = useCallback(async () => {
    if (isTesteGratis) {
      if (!gerais?.finalizacaoOnboarding && gerais?.ultValidadeProposta === null) {
        setOnBoardingBoasVindas({ aberto: true });
        setOnBoardingFinalizado(false);
      }
    }
  }, [isTesteGratis, gerais, setOnBoardingBoasVindas, setOnBoardingFinalizado]);

  const handleBuscarConfiguracoesGerais = useCallback(async () => {
    if (!gerais) {
      setLoading(true);
      try {
        await atualizarConfiguracoesGerais();

        setLoading(false);
      } catch (err) {
        handleError(err);
      }
    }
  }, [gerais, atualizarConfiguracoesGerais, handleError, setLoading]);

  const handleBuscarInformacoesEmpresa = useCallback(async () => {
    try {
      const { data } = await buscarDadosEmpresa();
      setMeusDados(data.data);
    } catch (err) {
      handleError(err);
    }
  }, [handleError, setMeusDados]);

  const handleBuscarRecursosPlano = useCallback(async () => {
    try {
      const { data } = await buscarRecursosPlano();
      const auxRecursosContratados: any = {};
      data.data?.recursos?.forEach((item) => (auxRecursosContratados[item.slugRecurso] = item.recursoContratado));
      setInformacoesPlano(data.data);
      setRecursosContratados(auxRecursosContratados);
    } catch (err) {
      handleError(err);
    }
  }, [setInformacoesPlano, setRecursosContratados, handleError]);

  const handleBuscarCodigoVendedor = useCallback(async () => {
    try {
      if (!profile?.sub) {
        return new Error('Usuário não autenticado');
      }

      const { data } = await buscarCodigoVendedor(profile?.sub);

      setVendedorAtual(data.data);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  const _buscarDadosCobranca = useCallback(async () => {
    try {
      const res = await consultarCobranca();
      if (res.data) {
        setDadosCobranca(res.data.data);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  //useEffect para buscar dados de cobranças
  useEffect(() => {
    _buscarDadosCobranca();
  }, [_buscarDadosCobranca]);

  //useEffect para buscar recursos do plano
  useEffect(() => {
    handleBuscarRecursosPlano();
  }, [handleBuscarRecursosPlano]);

  //useEffect para abrir OnBoarding
  useEffect(() => {
    handleOnBoarding();
  }, [handleOnBoarding]);

  //useEffect para buscar dados da empresa
  useEffect(() => {
    handleBuscarInformacoesEmpresa();
  }, [handleBuscarInformacoesEmpresa]);

  //useEffect para buscar configurações de propostas
  useEffect(() => {
    handleBuscarConfiguracoesGerais();
  }, [handleBuscarConfiguracoesGerais]);

  //useEffect para buscar código do vendedor
  useEffect(() => {
    handleBuscarCodigoVendedor();
  }, [handleBuscarCodigoVendedor]);
};

export default usePrivate;
