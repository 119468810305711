import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, Typography, LIcon, Button, CardButton } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineBoxCircleCheck, lineFullBox, lineSolarPanel, lineTrashAlt } from '@s_mart/regular-icons';

import useOnBoarding from 'shared/modais/onBoarding/store/onBoarding.store';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import AdicionarProdutoOuKitDialog from './adicionarProdutoOuKitDialog/adicionarProdutoOuKitDialog';
import ApresentacaoSimultaneidade from './apresentacaoSimultaneidade/apresentacaoSimultaneidade';
import BaseDeCalculoSistemaPaybackDialog from './baseDeCalculoPaybackDialog/baseDeCalculoPaybackDialog';
import { DivHoverLIcon } from './fields/fields.styles';
import FieldsPainel from './fields/fieldsPainel/fieldsPainel';
import FieldsInversores from './fields/fieldsInversores/fieldsInversores';
import FieldsOutros from './fields/fieldsOutros/fieldsOutros';
import FieldsKitsFotovoltaicos from './fields/fieldsKitsFotovoltaicos/fieldsKitsFotovoltaicos';
import FieldsValidadeValorPayback from './fields/fieldsValidadeValorPayback/fieldsValidadeValorPayback';
import FieldsKitsDeServicos from './fields/fieldsKitsDeServicos/fieldsKitsDeServicos';
import InformacoesAdicionaisDialog from './informacoesAdicionaisDialog/informacoesAdicionaisDialog';
import ProdutoDesatualizadoOuExcluidoDialog from './produtoDesatualizadoOuExcluidoDialog/produtoDesatualizadoOuExcluidoDialog';
import useGeradorDePropostaSistemaPayback from './useGeradorDePropostaSistemaPayback';
import useGeradorDeProposta from '../store/geradorDeProposta.store';
import { HeaderSistemaPayback } from './headerSistemaPayback/headerSistemaPayback';

const GeradorDePropostaSistemaPayback = () => {
  const methods = useFormContext();
  const { watch } = methods;
  const { geradorDeProposta } = useGeradorDeProposta();
  const { handleControleCaminhoGerador, handleCloseApresentacaoSimultaneidadeDialog } =
    useGeradorDePropostaSistemaPayback();
  const { recursosContratados } = useConfiguracoes();
  const { onBoardingFinalizado } = useOnBoarding();
  const telaMenorQue480 = useMediaQuery(`(max-width: 480px)`);
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const [_mostrarBaseDeCalculoDialog, setMostrarBaseDeCalculoDialog] = useState(false);
  const [_adicionarProdutoOuKitDialog, _setAdicionarProdutoOuKitDialog] = useState<{
    aberto: boolean;
    caminhoKits?: boolean;
  }>();
  const [_apresentacaoSimultaneidadeOnboarding, _setApresentacaoSimultaneidadeOnboarding] = useState(false);

  const _formValuesPainelAdicionado = watch('painelAdicionado');
  const _formValuesInversores = watch('inversores');
  const _formValuesOutros = watch('outros');
  const _formValuesKitsFotovoltaicos = watch('kitsFotovoltaicos');


  useEffect(() => {
    if (geradorDeProposta?.abrirOnboardingSimultaneidade) {
      _setApresentacaoSimultaneidadeOnboarding(true);
    }
  }, [geradorDeProposta?.abrirOnboardingSimultaneidade]);

  return (
    <>
      {geradorDeProposta?.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.aberto && (
        <ProdutoDesatualizadoOuExcluidoDialog />
      )}
      <Grid container spacing={2}>
        {_apresentacaoSimultaneidadeOnboarding && (
          <ApresentacaoSimultaneidade
            onClose={() => {
              _setApresentacaoSimultaneidadeOnboarding(false);
              handleCloseApresentacaoSimultaneidadeDialog();
            }}
          />
        )}
        {_adicionarProdutoOuKitDialog?.aberto && (
          <AdicionarProdutoOuKitDialog
            onClose={() => _setAdicionarProdutoOuKitDialog(undefined)}
            caminhoKits={_adicionarProdutoOuKitDialog?.caminhoKits}
          />
        )}
        {_mostrarBaseDeCalculoDialog && (
          <BaseDeCalculoSistemaPaybackDialog onClose={() => setMostrarBaseDeCalculoDialog(false)} />
        )}
        {geradorDeProposta?.informacoesAdicionaisDialog && <InformacoesAdicionaisDialog />}
        <Grid item xs={12}>
          <HeaderSistemaPayback />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            maxHeight: 'calc(100vh - 450px)',
            overflowY: 'auto',
            paddingRight: '4px',
          }}
        >
          {!geradorDeProposta?.caminhoGerador && (
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                margin: '24px 0px 28px 0px',
                textAlign: 'center',
              }}
            >
              <Typography variant='titleSM'>Adicione um produto individual ou um kit</Typography>
              <div style={{ display: 'flex', flexDirection: telaMenorQue480 ? 'column' : 'row', gap: '16px' }}>
                <CardButton
                  icon={lineFullBox}
                  text={
                    <Typography variant='titleSM' style={{ width: '158px' }}>
                      Adicionar kit
                    </Typography>
                  }
                  onClick={() =>
                    handleControleCaminhoGerador({ caminhoGerador: 'kits', methods, values: methods.getValues() })
                  }
                />
                <CardButton
                  icon={lineSolarPanel}
                  text={
                    <Typography variant='titleSM' style={{ width: '158px' }}>
                      Adicionar produto
                    </Typography>
                  }
                  onClick={() =>
                    handleControleCaminhoGerador({ caminhoGerador: 'produtos', methods, values: methods.getValues() })
                  }
                />
              </div>
            </Grid>
          )}
          {geradorDeProposta?.caminhoGerador === 'kits' && (
            <>
              <Grid item xs={12}>
                <FieldsKitsFotovoltaicos />
              </Grid>
              <Grid item xs={12}>
                <FieldsKitsDeServicos />
              </Grid>
              {_formValuesPainelAdicionado && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item style={{ width: 'calc(100% - 49px)' }}>
                      <FieldsPainel />
                    </Grid>
                    <Grid item style={{ display: 'flex', flex: 1, marginTop: 22 }}>
                      <DivHoverLIcon>
                        <LIcon
                          icon={lineTrashAlt}
                          size='32px'
                          removeMargin
                          style={{
                            cursor: 'pointer',
                            alignSelf:
                              telaMenorQue760 && !recursosContratados?.['valor-de-venda-unitario']
                                ? 'flex-end'
                                : 'center',
                          }}
                          onClick={() => {
                            methods.setValue('painelAdicionado', false);
                          }}
                        />
                      </DivHoverLIcon>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {Boolean(_formValuesInversores?.length) && (
                <Grid item xs={12}>
                  <FieldsInversores />
                </Grid>
              )}

              {Boolean(_formValuesOutros?.length) && (
                <Grid item xs={12}>
                  <FieldsOutros />
                </Grid>
              )}
            </>
          )}
          {geradorDeProposta?.caminhoGerador === 'produtos' && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FieldsPainel />
                </Grid>
                <Grid item xs={12}>
                  <FieldsInversores />
                </Grid>
                <Grid item xs={12}>
                  <FieldsOutros />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {geradorDeProposta?.caminhoGerador && (
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Button
              startIcon={<LIcon icon={lineBoxCircleCheck} size='20px' removeMargin />}
              variant='text'
              onClick={() => {
                methods.setValue('passouValidacaoProdutoDesatualizado', 'true');

                _setAdicionarProdutoOuKitDialog({
                  aberto: true,
                  caminhoKits: geradorDeProposta?.caminhoGerador === 'kits',
                });
              }}
              disabled={
                (geradorDeProposta?.caminhoGerador === 'produtos' || _formValuesPainelAdicionado) &&
                (geradorDeProposta?.caminhoGerador === 'kits'
                  ? _formValuesInversores?.length === 2
                  : _formValuesInversores?.length === 4) &&
                _formValuesOutros?.length === 6 &&
                _formValuesKitsFotovoltaicos?.length === 2
              }
            >
              <Typography variant='titleXS'>
                {geradorDeProposta?.caminhoGerador === 'produtos' &&
                  (!telaMenorQue480 ? 'Informar mais produtos e serviços' : 'Produtos e serviços')}
                {geradorDeProposta?.caminhoGerador === 'kits' &&
                  (!telaMenorQue480 ? 'Informar mais produtos, kits e serviços' : 'Produtos, kits e serviços')}
              </Typography>
            </Button>
          </Grid>
        )}
        {geradorDeProposta?.caminhoGerador && (
          <Grid item xs={12}>
            <FieldsValidadeValorPayback setMostrarBaseDeCalculoDialog={setMostrarBaseDeCalculoDialog} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default memo(GeradorDePropostaSistemaPayback);
