import { useCallback, useEffect, useReducer, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import domToImageMore from 'dom-to-image-more';

import { Button } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';

import { cadastrarCliente, editarCliente, listarClientesSelect, ultimaPropostaAtiva } from 'api/clientes/clientes';
import { finalizarOnBoarding } from 'api/onBoarding/onBoarding';
import { cadastrarProduto } from 'api/produtos/produtos';
import {
  atualizarConfigTipoProdutoDaProposta,
  buscarDadosBaseCalculo,
  buscarProposta,
  cadastrarProposta,
  gerarPdfProposta,
  listarVendedoresPropostas,
} from 'api/propostas/propostas';
import type {
  BuscarPropostaDados,
  ListagemVendedoresProposta,
  ProdutoGeradorProposta,
} from 'api/propostas/propostas.types';
import { queryClient } from 'api/queryClient';
import { useIsMount } from 'hooks/useIsMount/useIsMount';
import useOnBoarding from 'shared/modais/onBoarding/store/onBoarding.store';
import { usePainelClienteStore } from 'shared/painelCliente/painelCliente.store';
import useApp from 'store/app/app';
import { IEtapaLoadingGerador } from 'store/app/app.types';
import useAuth from 'store/auth/auth';

import GeradorDePropostaCliente from './geradorDePropostaCliente/geradorDePropostaCliente';
import GeradorDePropostaConsumo from './geradorDePropostaConsumo/geradorDePropostaConsumo';
import { IDadosProposta } from './geradorDeProposta.types';
import GeradorDePropostaSistemaPayback from './geradorDePropostaSistemaPayback/geradorDePropostaSistemaPayback';
import { initialValue, reducer } from './useGeradorDePropostaFunctionsReducer';
import useVisualizarPropostaDialog from '../visualizarPropostaDialog/store/visualizarPropostaDialog.store';
import useOnBoardingFinalizacao from '../onBoarding/onBoardingFinalizacao/useOnBoardingFinalizacao';
import useGeradorDeProposta from './store/geradorDeProposta.store';
import { useReaproveitarDadosDialogStore } from './reaproveitarDadosDialog/reaproveitarDadosDialog.store';
import { tiposLigacao } from './geradorDePropostaConsumo/geradorDePropostoConsumo.static';
import { GeradorDePropostaIntegracaoFornecedores } from './geradorDePropostaIntegracaoFornecedores/geradorDePropostaIntegracaoFornecedores';
import { GeradorDePropostaEtapa } from './store/geradorDeProposta.store.types';
import { useControleCaminhoGerador } from './controleCaminhoGerador/useControleCaminhoGerador';
import { FornecedorEnum, ProdutosFornecedores } from 'api/fornecedores/fornecedores.types';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { useIsTesteGratis } from 'hooks/useIsTesteGratis/useIsTesteGratis';
import { geradorDePropostaSubmit } from './geradorDePropostaCliente/geradorDePropostaClienteSubmit';

const useGeradorDePropostaFunctions = () => {
  const { profile } = useAuth();
  const _telaMenorQue360 = useMediaQuery(`(max-width: 360px)`);
  const _telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const { setLoading, setLoadingGerador, setNotification, handleError, setMensagemLoading } = useApp();
  const isTesteGratis = useIsTesteGratis();
  const { etapa, setEtapa, geradorDeProposta, setGeradorDeProposta } = useGeradorDeProposta();
  const { handleControleCaminhoGerador } = useControleCaminhoGerador();
  const { setVisualizarPropostaDialog } = useVisualizarPropostaDialog();
  const { gerais, atualizarConfiguracoesGerais, propostasGeradas, setPropostasGeradas } = useConfiguracoes();
  const isMount = useIsMount();
  const { handleCloseOnBoardingFinalizacao } = useOnBoardingFinalizacao();
  const { onBoardingFinalizado } = useOnBoarding();
  const reaproveitarDados = useReaproveitarDadosDialogStore();
  const { telaCliente, handleBuscarAtividades, handleBuscarPropostas } = usePainelClienteStore();

  const [geradorDePropostaFunctions, setGeradorDePropostaFunctions] = useReducer(reducer, initialValue);
  const [initialValues, _setInitialValues] = useState<any>({});
  const qtdFornecedoresAtivos = gerais?.fornecedores?.length || 0;

  const parseDescricao = (element?: string) => {
    if (element) {
      return element
        .replace(/(<table>|<tr>|<td>|<th>|<\/table>)/g, '')
        .replace(/(<\/th>|<\/td>)/g, ' - ')
        .replace(/(<\/tr>|<br>)/g, '\n');
    } else {
      return '';
    }
  };

  const handleClose = useCallback(
    (gerarProposta?: boolean) => {
      setGeradorDeProposta({
        aberto: false,
        abrirProdutoExcluidoOuPrecoDesatualizadoDialog: undefined,
        clonar: undefined,
        clienteSelecionado: undefined,
        onBoarding: undefined,
        caminhoGerador: undefined,
        alertaBaseCalculo: undefined,
        concessionarias: undefined,
        informacoesAdicionaisDialog: undefined,
        submit: undefined,
        previousProdutosOuKits: undefined,
        cliente: undefined,
      });
      if (!gerarProposta && !onBoardingFinalizado) {
        handleCloseOnBoardingFinalizacao();
      }
    },
    [setGeradorDeProposta, etapa, onBoardingFinalizado, handleCloseOnBoardingFinalizacao]
  );

  const handleVoltar = useCallback(
    (methods: UseFormReturn) => {
      if (etapa !== GeradorDePropostaEtapa.DADOS_CLIENTE) {
        if (etapa === GeradorDePropostaEtapa.SISTEMA_PAYBACK && !qtdFornecedoresAtivos) {
          methods.setValue('passouValidacaoProdutoDesatualizado', 'true');
          setGeradorDeProposta({ abrirProdutoExcluidoOuPrecoDesatualizadoDialog: undefined });
          setEtapa(GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO);
        } else {
          methods.setValue('passouValidacaoProdutoDesatualizado', 'true');
          setGeradorDeProposta({ abrirProdutoExcluidoOuPrecoDesatualizadoDialog: undefined });
          setEtapa(etapa - 1);
        }
      } else {
        handleClose();
      }
    },
    [etapa, handleClose]
  );

  const handleControleGerador = useCallback(() => {
    if (etapa === GeradorDePropostaEtapa.DADOS_CLIENTE) {
      return {
        title: 'Cliente',
        tamanhoModal: 'sm',
        conteudo: <GeradorDePropostaCliente vendedores={geradorDePropostaFunctions?.vendedores} />,
        primaryButton: 'Próximo',
        secondaryButton: 'Cancelar',
      };
    } else if (etapa === GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO) {
      return {
        title: 'Instalação e consumo',
        tamanhoModal: 'md',
        conteudo: <GeradorDePropostaConsumo />,
        primaryButton: 'Próximo',
        secondaryButton: 'Voltar',
      };
    } else if (etapa === GeradorDePropostaEtapa.INTEGRACAO_FORNECEDORES) {
      return {
        title: 'Produtos dos fornecedores',
        tamanhoModal: 'lg',
        conteudo: <GeradorDePropostaIntegracaoFornecedores />,
        esconderFooterDialog: true,
      };
    } else if (etapa === GeradorDePropostaEtapa.SISTEMA_PAYBACK) {
      return {
        title: 'Sistema x Payback',
        tamanhoModal: 'lg',
        conteudo: <GeradorDePropostaSistemaPayback />,
        primaryButton: !_telaMenorQue600 ? 'Gerar proposta' : 'Gerar',
        esconderFooterDialog: !geradorDeProposta?.caminhoGerador,
        secondaryButton: qtdFornecedoresAtivos ? 'Selecionar mais produtos' : 'Voltar',
        additionalAction: (
          <Button variant='neutral' onClick={() => setGeradorDeProposta({ informacoesAdicionaisDialog: true })}>
            Info{!_telaMenorQue360 && 'rmações'}
            {!_telaMenorQue600 && ' adicionais'}
          </Button>
        ),
      };
    }
  }, [
    etapa,
    setGeradorDeProposta,
    geradorDePropostaFunctions?.vendedores,
    geradorDeProposta?.caminhoGerador,
    _telaMenorQue360,
    _telaMenorQue600,
  ]);

  const handleMontarProdutosPayloadGerador = useCallback(
    async (values: any) => {
      const _produtos: ProdutoGeradorProposta[] = [];

      try {
        const _payloadCadastroPainel = {
          nome: values.modeloPainel?.label || values.modeloPainel,
          potencia: Number(values.potenciaPaineis),
          garantia: Number(values.garantiaPaineis),
          tipoGarantia: Number(values.tipoGarantiaPaineis) !== 0 ? 1 : 0,
          tipo: 0,
          valorVenda: Number(values?.valorVendaPaineis) || undefined,
        };
        if (
          values.modeloPainel &&
          typeof values.modeloPainel === 'object' &&
          Boolean(Object.values(values.modeloPainel)[0])
        ) {
          _produtos.push({
            ..._payloadCadastroPainel,
            codigo: values.modeloPainel?.value,
            quantidade: Number(values.quantidadePaineis),
            valorVenda: Number(values?.valorVendaPaineis) || undefined,
          });
        } else if (typeof values.modeloPainel === 'string') {
          setLoadingGerador({ etapa: IEtapaLoadingGerador.CADASTROPRODUTOS, produtosCadastrados: true });
          const { data } = await cadastrarProduto(_payloadCadastroPainel);

          _produtos.push({
            ..._payloadCadastroPainel,
            codigo: data?.data,
            quantidade: Number(values.quantidadePaineis),
            valorVenda: Number(values?.valorVendaPaineis) || undefined,
          });
        }

        values.inversores?.forEach(async (item: any) => {
          const _payloadCadastroInversor = {
            nome: item.modeloInversores?.label || item.modeloInversores,
            potencia: Number(item.potenciaInversores),
            garantia: Number(item.garantiaInversores),
            tipoGarantia: Number(item.tipoGarantiaInversores) !== 0 ? 1 : 0,
            tipo: 1,
            valorVenda: Number(item?.valorVendaInversores) || undefined,
            idIntegracaoForncedor: item?.idIntegracaoFornecedor || undefined,
          };
          if (typeof item.modeloInversores === 'object') {
            _produtos.push({
              ..._payloadCadastroInversor,
              codigo: item?.idIntegracaoFornecedor ? undefined : item.modeloInversores?.value,
              quantidade: Number(item.quantidadeInversores),
              valorVenda: Number(item?.valorVendaInversores) || undefined,
            });
          } else if (typeof item.modeloInversores === 'string') {
            setLoadingGerador({ etapa: IEtapaLoadingGerador.CADASTROPRODUTOS, produtosCadastrados: true });
            const { data } = await cadastrarProduto(_payloadCadastroInversor);

            _produtos.push({
              ..._payloadCadastroInversor,
              codigo: item?.idIntegracaoFornecedor ? undefined : data?.data,
              quantidade: Number(item.quantidadeInversores),
              valorVenda: Number(item?.valorVendaInversores) || undefined,
            });
          }
        });

        values.outros?.forEach(async (item: any) => {
          const _payloadCadastroOutro = {
            nome: item.modeloOutros?.label || item.modeloOutros,
            garantia: item.garantiaOutros ? Number(item.garantiaOutros) : null,
            tipoGarantia: Number(item.tipoGarantiaOutros) !== 0 ? 1 : 0,
            tipo: 2,
            valorVenda: Number(item?.valorVendaOutros) || undefined,
            idIntegracaoForncedor: item?.idIntegracaoFornecedor || undefined,
          };
          if (typeof item.modeloOutros === 'object') {
            _produtos.push({
              ..._payloadCadastroOutro,
              codigo: item?.idIntegracaoFornecedor ? undefined : item.modeloOutros?.value,
              quantidade: Number(item.quantidadeOutros) || undefined,
              valorVenda: Number(item?.valorVendaOutros) || undefined,
            });
          } else if (typeof item.modeloOutros === 'string') {
            setLoadingGerador({ etapa: IEtapaLoadingGerador.CADASTROPRODUTOS, produtosCadastrados: true });
            const { data } = await cadastrarProduto(_payloadCadastroOutro);

            _produtos.push({
              ..._payloadCadastroOutro,
              codigo: item?.idIntegracaoFornecedor ? undefined : data?.data,
              quantidade: Number(item.quantidadeOutros) || undefined,
              valorVenda: Number(item?.valorVendaOutros) || undefined,
            });
          }
        });

        values.kitsFotovoltaicos?.forEach(async (item: any) => {
          const _payloadCadastroKitFotovoltaico = {
            codigoProdutoFornecedor: item?.codigoProdutoFornecedor || undefined,
            idIntegracaoForncedor: item?.idIntegracaoFornecedor || undefined,
            nome: item.modeloKitsFotovoltaicos?.label || item.modeloKitsFotovoltaicos,
            potencia: Number(item.potenciaKitsFotovoltaicos),
            descricao: item?.descricaoKitsFotovoltaicos || undefined,
            descricaoGarantia: item?.descricaoGarantiaKitsFotovoltaicos || undefined,
            tipo: 3,
            valorVenda: Number(item?.valorVendaKitsFotovoltaicos) || undefined,
          };
          if (typeof item.modeloKitsFotovoltaicos === 'object') {
            if (item?.codigoProdutoFornecedor || item?.idIntegracaoFornecedor) {
              _produtos.push(_payloadCadastroKitFotovoltaico);
            } else {
              _produtos.push({
                ..._payloadCadastroKitFotovoltaico,
                codigo: item.modeloKitsFotovoltaicos?.value,
              });
            }
          } else if (typeof item.modeloKitsFotovoltaicos === 'string') {
            setLoadingGerador({ etapa: IEtapaLoadingGerador.CADASTROPRODUTOS, produtosCadastrados: true });

            if (
              _payloadCadastroKitFotovoltaico?.codigoProdutoFornecedor ||
              _payloadCadastroKitFotovoltaico?.idIntegracaoForncedor
            ) {
              _produtos.push(_payloadCadastroKitFotovoltaico);
            } else {
              const { data } = await cadastrarProduto(_payloadCadastroKitFotovoltaico);
              _produtos.push({ ..._payloadCadastroKitFotovoltaico, codigo: data?.data });
            }
          }
        });

        values.kitsDeServicos?.forEach(async (item: any) => {
          const _payloadCadastroKitsDeServico = {
            nome: item.modeloKitsDeServicos?.label || item.modeloKitsDeServicos,
            tipo: 4,
            descricao: item?.descricaoKitsDeServicos || undefined,
            descricaoGarantia: item?.descricaoGarantiaKitsDeServicos || undefined,
            valorVenda: Number(item?.valorVendaKitsDeServicos) || undefined,
          };
          if (typeof item.modeloKitsDeServicos === 'object') {
            _produtos.push({
              ..._payloadCadastroKitsDeServico,
              codigo: item.modeloKitsDeServicos?.value,
            });
          } else if (typeof item.modeloKitsDeServicos === 'string') {
            setLoadingGerador({ etapa: IEtapaLoadingGerador.CADASTROPRODUTOS, produtosCadastrados: true });
            const { data } = await cadastrarProduto(_payloadCadastroKitsDeServico);

            _produtos.push({
              ..._payloadCadastroKitsDeServico,
              codigo: data?.data,
            });
          }
        });
      } catch (err) {
        handleError(err);
      }

      return _produtos;
    },
    [handleError]
  );

  const _handleGerarPropostaEPdf = async (values: any) => {
    setLoadingGerador({ aberto: true });
    setGeradorDeProposta({ submit: true });
    let _produtos: ProdutoGeradorProposta[] = [];
    let _dadosProposta: { data: IDadosProposta } = { data: {} };

    if (!geradorDePropostaFunctions?.produtos?.length) {
      _produtos = await handleMontarProdutosPayloadGerador(values);

      setGeradorDePropostaFunctions({ type: 'produtos', produtosPayload: _produtos });
    }

    setLoadingGerador({ etapa: IEtapaLoadingGerador.GERANDOGRAFICOS });
    //Promise.resolve para garantir que as imagens existam quando o domToImageMore for printar (funções abaixo)
    await new Promise((resolve) => setTimeout(resolve, 400));

    const _graficoConsumo = await domToImageMore.toPng(document.getElementById('grafico-consumo') as HTMLDivElement, {
      quality: 1,
    });
    const _graficoFaturas = await domToImageMore.toPng(document.getElementById('grafico-faturas') as HTMLDivElement, {
      quality: 1,
    });
    const _graficoPayback = await domToImageMore.toPng(document.getElementById('grafico-payback') as HTMLDivElement, {
      quality: 1,
    });
    const _tabelaPayback = await domToImageMore.toPng(document.getElementById('tabela-payback') as HTMLDivElement, {
      quality: 1,
    });

    //Promise.resolve para garantir que as imagens existam quando o domToImageMore for printar (funções abaixo)
    await new Promise((resolve) => setTimeout(resolve, 600));

    const consumoMensal: any[] = [];

    // Faz lógica para montar o payload de consumo mensal - desculpa por ver isso aqui
    values.consumoMensal?.forEach((contas: any, index: number) => {
      Object?.values(contas)?.forEach((meses: any) => {
        meses?.forEach((value: any) => {
          if (value?.valor) {
            consumoMensal.push({
              mes: Number(value.mes),
              valor: Number(value.valor),
              ordem: Number(index + 1),
            });
          }
        });
      });
    });

    let _potenciaInstalada = Number(values.potenciaInstalada || values.potenciaInversor);

    if (values.tipoLigacao?.value === '3') {
      if (values.tipoDimensionamento === '1') {
        _potenciaInstalada = Number(values.potenciaSistemaForaPontaMaisPonta);
      } else {
        _potenciaInstalada = Number(values.potenciaSistemaForaPonta);
      }
    }

    if (
      (_produtos.length || geradorDePropostaFunctions?.produtos?.length) &&
      !geradorDePropostaFunctions?.dadosProposta?.data?.codigoProposta
    ) {
      setLoadingGerador({ etapa: IEtapaLoadingGerador.GERANDOPROPOSTA });

      const _payload = {
        alturaPainel: Number(values.alturaPainel),
        areaMinima: Number(values.areaMinima),
        aumentoConsumo: Number(values.aumentoConsumo),
        clonada: values.clonada && geradorDeProposta?.clonar?.codigo,
        codigoCidade: Number(values.cidadeProposta?.value),
        codigoCliente: values.nome?.value ?? Number(values.codigoCliente),
        codigoConcessionaria: Number(values.concessionaria?.value),
        codigoLead: values.codigoLead,
        codigoVendedor: Number(values.vendedor?.value),
        consumoMedio: Number(values.consumoMedio),
        consumoMedioForaPonta: values.tipoLigacao?.value === '3' ? Number(values.consumoMedioForaPonta) : null,
        consumoMedioPonta: values.tipoLigacao?.value === '3' ? Number(values.consumoMedioPonta) : null,
        consumoMensal: consumoMensal.length > 0 ? consumoMensal : undefined,
        consumoTusdg: values.tipoLigacao?.value === '3' ? values.consumoACompensar || 0 : null,
        custoDemandaContratada:
          values.tipoLigacao?.value === '3'
            ? Number(values.demandaContratada) * Number(values.tarifaDemandaContratada)
            : null,
        qtdeDemandaContratada: values.tipoLigacao?.value === '3' ? Number(values.demandaContratada) : null,
        degradacaoSistema: Number(values.degradacaoSistema),
        economia25: Number(values.economia25),
        economiaAnual: values.economiaAnual,
        faturaSemSistemaAtual: Number(values.precoKwh) * Number(values.consumoMedio),
        geracaoSistema:
          //os valores de geração devem ser conforme o tipo de ligação e de consumo
          values.tipoLigacao?.value === '3'
            ? values.tipoDimensionamento === '0'
              ? Number(values.geracaoSistemaForaPonta)
              : Number(values.geracaoSistemaForaPontaMaisPonta)
            : Number(values.geracaoSistema),
        graficoConsumo: _graficoConsumo?.substring(22),
        graficoFaturas: _graficoFaturas?.substring(22),
        graficoPayback: _graficoPayback?.substring(22),
        infoAdicionais: {
          descricao: values.descricao || values.infoAdicionais?.descricao,
          infoAdicionaisSalvar: Boolean(values.infoAdicionaisSalvar),
        },
        larguraPainel: Number(values.larguraPainel),
        mediaFatAno1: values?.faturasComSistema[0],
        mediaFatAno2: values?.faturasComSistema[1],
        mediaFatAno3: values?.faturasComSistema[2],
        mediaFaturaComSistema: values?.mediaFaturaComSistema,
        mediaFaturaSemSistema: values?.mediaFaturaSemSistema,
        mediaValorFioB: values?.mediaValorFioB,
        perdaSistema: Number(values.perdaSistema),
        fatorSimultaneidade: Number(values.simultaneidade),
        potenciaInstalada: _potenciaInstalada,
        potenciaInversor: Number(values.potenciaInversor),
        potenciaPaineis: Number(values.potenciaPaineis),
        precoKwh: Number(values.precoKwh),
        produtos: _produtos.length ? _produtos : geradorDePropostaFunctions?.produtos || [],
        quantidadePaineis: Number(values.quantidadePaineis),
        retornoInvestimento: values.retornoInvestimento,
        sistemaDimensionado: values.tipoLigacao?.value === '3' ? Number(values.tipoDimensionamento) : null,
        tarifaDemandaContratada: values.tipoLigacao?.value === '3' ? Number(values.tarifaDemandaContratada) : null,
        tarifaForaPonta: values.tipoLigacao?.value === '3' ? Number(values.tarifaForaPonta) : null,
        tarifaPonta: values.tipoLigacao?.value === '3' ? Number(values.tarifaPonta) : null,
        tarifaTusdg: values.tipoLigacao?.value === '3' ? Number(values.tarifaTusdg) : null,
        tabelaPayback: _tabelaPayback?.substring(22),
        taxaInflacao: Number(values.taxaInflacao),
        tipoLigacao: Number(values.tipoLigacao?.value),
        validade: dayjs().add(values.validade, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
        valorGastoEnergia30AnosComSistema: values?.valorGastoEnergia30AnosComSistema,
        valorGastoEnergia30AnosSemSistema: values?.valorGastoEnergia30AnosSemSistema,
        valorTotal: Number(values.valorTotal),
        valorTusdg: values.tipoLigacao?.value === '3' ? Number(values.valorAPagarTusdG || 0) : null,
      };
      _dadosProposta = await cadastrarProposta(_payload).catch((err) => {
        if ((err as any)?.response?.data?.erro?.mensagem?.includes('imagem')) {
          // captureException(err, { extra: _payload });
          // SENTRY - Aqui costumava ter um captureException, porém foi removido para parar de quebrar o solarfy nos iphone
        }
        throw err;
      });

      if (!onBoardingFinalizado) {
        await finalizarOnBoarding();
      }

      setGeradorDePropostaFunctions({ type: 'dadosProposta', dadosPropostaPayload: _dadosProposta?.data });
      if (
        (gerais?.tipoProduto === 0 && geradorDeProposta?.caminhoGerador === 'kits') ||
        (gerais?.tipoProduto === 1 && geradorDeProposta?.caminhoGerador === 'produtos') ||
        (!gerais?.tipoProduto && gerais?.tipoProduto !== 0)
      ) {
        //await nesta informação para que o 'atualizarConfiguracoesProposta()' busque corretamente o último dado informado aqui
        await atualizarConfigTipoProdutoDaProposta({ tipo: geradorDeProposta?.caminhoGerador === 'kits' ? 1 : 0 });
        atualizarConfiguracoesGerais();
      }

      setNotification({ title: 'Nova proposta gerada!' });
      queryClient.clear();
    }

    if (_dadosProposta.data?.data?.codigoProposta || geradorDePropostaFunctions?.dadosProposta?.data?.codigoProposta) {
      setLoadingGerador({ etapa: IEtapaLoadingGerador.GERANDOPDF });
      const { data: _pdf } = await gerarPdfProposta({
        acao: 3,
        codigoProposta:
          _dadosProposta.data?.data?.codigoProposta ||
          geradorDePropostaFunctions?.dadosProposta?.data?.codigoProposta ||
          -10,
        dataInicialPreenchimento: values.dataInicialPreenchimento,
        hexCor: gerais?.hexCor,
      });
      setMensagemLoading('');
      setVisualizarPropostaDialog({
        aberto: true,
        fluxoGerador: true,
        dadosProposta: {
          cidade: values.dadosCidade?.value,
          cliente: values.nome.label ?? values.nome,
          codigo:
            _dadosProposta.data?.data?.codigoProposta ||
            geradorDePropostaFunctions?.dadosProposta?.data?.codigoProposta ||
            0,
          dataGeracao: dayjs().toISOString(),
          dataVisualizacao: '',
          link: _pdf?.data?.link,
          nomeResponsavel: values.nomeResponsavel,
          numero: (queryClient as any)?.getQueryData('propostas')?.pages?.[0]?.data?.data?.[0]?.numero + 1 || 1,
          pdf: _pdf?.data?.pdf,
          situacao: 1,
          tipoCliente: values.tipoPessoa,
          validade: dayjs().add(values.validade, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
          valorTotal: values.valorTotal,
          vendedor: values.vendedor?.label,
        },
      });
      setLoadingGerador({ aberto: false, etapa: undefined, produtosCadastrados: false });
      setGeradorDeProposta({ submit: undefined });

      if (telaCliente.open) {
        handleBuscarPropostas();
        handleBuscarAtividades({});
      } else {
        geradorDeProposta.callbackTelaCrm?.();
      }

      if (isTesteGratis && gerais?.ultValidadeProposta === null) {
        await atualizarConfiguracoesGerais();
      }

      //handleClose(true) para indicar na função de fechar o gerador, que está
      //sendo chamada na função de gerar a proposta, evitando que o onboarding
      //seja encerrado sem querer
      handleClose(true);
    }
  };

  const handleSubmitGerador = async (values: any) => {
    try {
      setPropostasGeradas((Number(propostasGeradas) || 0) + 1);
      await _handleGerarPropostaEPdf(values);
    } catch (err) {
      handleError(err);
      setGeradorDeProposta({ submit: undefined });
      if ((err as any)?.response?.data?.erro?.mensagem?.includes('imagem')) {
        await _handleGerarPropostaEPdf(values).catch((err) => {
          setGeradorDeProposta({ submit: undefined });
          handleError(err);
        });
      }
    }
  };

  const handleProximoESubmit = async (values: any, methods: UseFormReturn<FieldValues, any>) => {
    let _codigo: number | null = values.codigoCliente || null;

    if (etapa === GeradorDePropostaEtapa.DADOS_CLIENTE) {
      if (
        geradorDePropostaFunctions?.ultimoCliente !== values.nome ||
        (typeof values.nome === 'string' && geradorDeProposta?.clienteSelecionado)
      ) {
        const { payloadCliente, valoresMudaram } = await geradorDePropostaSubmit(values);

        if (!geradorDeProposta?.clienteSelecionado) {
          setMensagemLoading('Cadastrando cliente');
          setLoading(true);
          try {
            const res = await cadastrarCliente(payloadCliente);

            _codigo = res.data?.data;
            methods.setValue('codigoCliente', _codigo);

            setGeradorDePropostaFunctions({
              type: 'ultimoCliente',
              ultimoClientePayload: payloadCliente.nome,
            });
            setGeradorDeProposta({ clienteSelecionado: true });
            setNotification({ title: 'Novo cliente cadastrado!' });
          } catch (err) {
            handleError(err);
            setGeradorDePropostaFunctions({ type: 'ultimoCliente', ultimoClientePayload: '' });
          }

          setMensagemLoading('');
          setLoading(false);
        } else {
          setLoading(true);
          try {
            if (valoresMudaram) {
              if (typeof values.nome === 'string') {
                const { data } = await listarClientesSelect({ search: values.nome });

                _codigo = data.data[0].codigo;
                methods.setValue('codigoCliente', _codigo);
                await editarCliente(_codigo, payloadCliente);
              } else {
                await editarCliente(values.nome?.value, payloadCliente);
              }
            }
          } catch (err) {
            handleError(err);
          }
          setLoading(false);
        }
      }

      const { data } = await ultimaPropostaAtiva(values.nome.value || _codigo);

      if (data.data === null) {
        setEtapa(GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO);
      } else if (data.data.codigoLead && data.data.codigoProposta && !telaCliente.open) {
        reaproveitarDados.handleOpen({
          cliente: {
            id: values.nome.value || _codigo,
            nome: values.nome.label || values.nome,
          },
          codigoLead: data?.data?.codigoLead,
          codigoProposta: data.data.codigoProposta,
          callback: ({ reaproveitar }) => {
            if (reaproveitar) {
              _handleBuscarDados({
                codigoProposta: data.data.codigoProposta,
                tipo: 'reaproveitar',
                codigoLead: data?.data?.codigoLead,
                methods,
              });
            } else {
              const _valoresAtuais = methods?.getValues() || {};

              _setInitialValues({ ..._valoresAtuais });
            }

            setEtapa(GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO);
          },
        });
      } else {
        const _valoresAtuais = methods?.getValues() || {};

        _setInitialValues({ ..._valoresAtuais, codigoLead: data?.data?.codigoLead });
        setEtapa(GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO);
      }
    }

    if (etapa === GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO) {
      if (!values.potenciaPaineis || !values.perdaSistema || !values.larguraPainel || !values.alturaPainel) {
        setGeradorDeProposta({ alertaBaseCalculo: { dimensionamento: true } });
        setNotification({ title: 'Informe os valores para o dimensionamento.', message: '', variant: 'warning' });
      } else {
        setGeradorDeProposta({ alertaBaseCalculo: { dimensionamento: false } });

        if (qtdFornecedoresAtivos) {
          if (geradorDeProposta.clonar) {
            setEtapa(GeradorDePropostaEtapa.SISTEMA_PAYBACK);
          } else {
            setEtapa(GeradorDePropostaEtapa.INTEGRACAO_FORNECEDORES);
          }
        } else {
          setEtapa(GeradorDePropostaEtapa.SISTEMA_PAYBACK);
        }
      }
    }

    if (etapa === GeradorDePropostaEtapa.INTEGRACAO_FORNECEDORES) {
      const kits = values.kitsFornecedoresSelecionados;

      if (!kits?.length) {
        const kitsAtuais = values.kitsFotovoltaicos;

        if (!kitsAtuais?.length) {
          handleControleCaminhoGerador({ caminhoGerador: 'produtos', values, methods });
          setEtapa(GeradorDePropostaEtapa.SISTEMA_PAYBACK);
        } else {
          handleControleCaminhoGerador({ caminhoGerador: 'kits', values, methods });
          setEtapa(GeradorDePropostaEtapa.SISTEMA_PAYBACK);
        }
      } else {
        methods.setValue('kitsFornecedoresSelecionados', undefined);

        if (values.fornecedores?.value?.codigo === FornecedorEnum.EDELTEC) {
          const kitsFotovoltaicos: ProdutosFornecedores[] = [];
          const inversores: ProdutosFornecedores[] = [];
          const outrosProdutos: ProdutosFornecedores[] = [];
          const possuiKitFornecedor = !!values.kitsFotovoltaicos?.filter((it: any) => it.idIntegracaoFornecedor)
            ?.length;

          kits.map((it: ProdutosFornecedores) => {
            if (it?.categoria?.toLowerCase().includes('geradores')) {
              kitsFotovoltaicos.push(it);
            } else if (it?.categoria?.toLowerCase().includes('inversores')) {
              inversores.push(it);
            } else {
              outrosProdutos.push(it);
            }
          });

          if (kitsFotovoltaicos?.length) {
            handleControleCaminhoGerador({ caminhoGerador: 'kits', values, methods });

            methods.setValue('kitsFotovoltaicos', [
              ...(values.kitsFotovoltaicos || []),
              ...kitsFotovoltaicos.map((kit: ProdutosFornecedores) => ({
                modeloKitsFotovoltaicos: kit.nome,
                potenciaKitsFotovoltaicos: kit.potencia,
                valorVendaKitsFotovoltaicos: kit?.precoVenda || 0,
                descricaoKitsFotovoltaicos: parseDescricao(kit?.descricaoCompleta),
                descricaoGarantiaKitsFotovoltaicos: undefined,
                idIntegracaoFornecedor: kit.codigo,
              })),
            ]);
          } else if ((inversores?.length || outrosProdutos?.length) && !possuiKitFornecedor) {
            handleControleCaminhoGerador({ caminhoGerador: 'produtos', values, methods });
          }

          if (inversores?.length) {
            methods.setValue('inversores', [
              ...(values?.inversores || []),
              ...inversores.map((kit: ProdutosFornecedores) => ({
                modeloInversores: kit.nome,
                potenciaInversores: kit.potencia,
                quantidadeInversores: undefined,
                garantiaInversores: undefined,
                tipoGarantiaInversores: 1,
                valorVendaInversores: kit?.precoVenda || 0,
                idIntegracaoFornecedor: kit.codigo,
              })),
            ]);
          }

          if (outrosProdutos?.length) {
            methods.setValue('outros', [
              ...(values?.outros || []),
              ...outrosProdutos.map((kit: ProdutosFornecedores) => ({
                garantiaOutros: undefined,
                modeloOutros: kit.nome,
                quantidadeOutros: undefined,
                tipoGarantiaOutros: 1,
                valorVendaOutros: kit?.precoVenda || 0,
                idIntegracaoFornecedor: kit.codigo,
              })),
            ]);
          }
        } else {
          handleControleCaminhoGerador({ caminhoGerador: 'kits', values, methods });

          methods.setValue('kitsFotovoltaicos', [
            ...(values.kitsFotovoltaicos || []),
            ...kits.map((kit: ProdutosFornecedores) => ({
              modeloKitsFotovoltaicos: kit.nome,
              potenciaKitsFotovoltaicos: kit.potencia,
              valorVendaKitsFotovoltaicos: 0,
              descricaoKitsFotovoltaicos: kit.descricaoCompleta,
              descricaoGarantiaKitsFotovoltaicos: undefined,
              codigoProdutoFornecedor: Number(kit.codigo),
            })),
          ]);
        }

        setEtapa(GeradorDePropostaEtapa.SISTEMA_PAYBACK);
      }
    }

    if (etapa === GeradorDePropostaEtapa.SISTEMA_PAYBACK) {
      if (!values.degradacaoSistema || !values.taxaInflacao || !values.precoKwh) {
        setGeradorDeProposta({ alertaBaseCalculo: { payback: true } });
        setNotification({ title: 'Informe os valores para o payback.', message: '', variant: 'warning' });
      } else {
        setGeradorDeProposta({ alertaBaseCalculo: { payback: false } });

        await handleSubmitGerador(values);
      }
    }
  };

  const _handleBuscarDados = useCallback(
    async ({
      tipo,
      codigoProposta,
      codigoLead,
      methods,
    }: {
      tipo: 'clonar' | 'reaproveitar';
      codigoProposta: number;
      codigoLead?: number;
      methods?: UseFormReturn;
    }) => {
      if (!geradorDePropostaFunctions?.vendedores?.data) {
        return;
      }

      setLoading(true);
      try {
        const _response = await buscarProposta(codigoProposta);
        const _dados = _response.data.data as BuscarPropostaDados;

        if (_dados) {
          const _painel = _dados.produtos.filter((produto) => produto.tipo === 0);
          const _inversores = _dados.produtos.filter((produto) => produto.tipo === 1);
          const _outros = _dados.produtos.filter((produto) => produto.tipo === 2);
          const _kitsFotovoltaicos = _dados.produtos.filter((produto) => produto.tipo === 3);
          const _kitsDeServicos = _dados.produtos.filter((produto) => produto.tipo === 4);

          const _grupoAForaPonta = Boolean(_dados?.tipoLigacao === 3 && _dados?.sistemaDimensionado === 0);
          const _grupoAForaPontaMaisPonta = Boolean(_dados?.tipoLigacao === 3 && _dados?.sistemaDimensionado === 1);

          //setGeradorProposta responsável por dizer qual o caminho da proposta que foi usado
          setGeradorDeProposta({
            clienteSelecionado: true,
            caminhoGerador: _kitsFotovoltaicos?.length ? 'kits' : 'produtos',
          });

          const _initValuesInversores = _inversores.map((inversor) => ({
            garantiaInversores: String(inversor.garantia),
            modeloInversores: {
              label: inversor.nome,
              value: inversor.codigo,
            },
            tipoGarantiaInversores: inversor.tipoGarantia,
            potenciaInversores: String(inversor.potencia),
            quantidadeInversores: String(inversor.quantidade),
            valorVendaInversores: inversor.valorVenda ? String(inversor.valorVenda) : undefined,
            codigo: inversor.codigo,
          }));

          const _initValuesOutros = _outros.map((outro) => ({
            garantiaOutros: outro.garantia ? String(outro.garantia) : null,
            modeloOutros: {
              label: outro.nome,
              value: outro.codigo,
            },
            tipoGarantiaOutros: outro.tipoGarantia,
            quantidadeOutros: outro.quantidade,
            valorVendaOutros: outro.valorVenda ? String(outro.valorVenda) : undefined,
            codigo: outro.codigo,
          }));

          const _initValuesKitsFotovoltaicos = _kitsFotovoltaicos.map((kitsFotovoltaicos) => ({
            modeloKitsFotovoltaicos: {
              label: kitsFotovoltaicos.nome,
              value: kitsFotovoltaicos.codigo,
            },
            descricaoKitsFotovoltaicos: parseDescricao(kitsFotovoltaicos?.descricao),
            descricaoGarantiaKitsFotovoltaicos: kitsFotovoltaicos.descricaoGarantia,
            potenciaKitsFotovoltaicos: String(kitsFotovoltaicos.potencia),
            valorVendaKitsFotovoltaicos: kitsFotovoltaicos.valorVenda
              ? String(kitsFotovoltaicos.valorVenda)
              : undefined,
            codigo: kitsFotovoltaicos.codigo || undefined,
            codigoProdutoFornecedor: kitsFotovoltaicos.codigoProdutoFornecedor || undefined,
          }));

          const _initValuesKitsDeServicos = _kitsDeServicos.map((kitsDeServicos) => ({
            modeloKitsDeServicos: {
              label: kitsDeServicos.nome,
              value: kitsDeServicos.codigo,
            },
            descricaoKitsDeServicos: kitsDeServicos.descricao,
            descricaoGarantiaKitsDeServicos: kitsDeServicos.descricaoGarantia,
            valorVendaKitsDeServicos: kitsDeServicos.valorVenda ? String(kitsDeServicos.valorVenda) : undefined,
            codigo: kitsDeServicos.codigo,
          }));

          const _vendedor = geradorDePropostaFunctions?.vendedores?.data?.find(
            (vendedor) => vendedor.codigo === _dados.codigoVendedor
          );
          const _concessionaria = geradorDeProposta?.concessionarias?.find((concessionaria) => {
            return concessionaria.value === _dados.codigoConcessionaria;
          });

          const _contas: any = {};

          Object.values(_dados.consumoMensal).forEach((values: any, index) => {
            _contas[index] = values.map((value: any) => {
              return String(value.valor);
            });
          });

          const consumoMensalPayload = Object.values(_contas)
            ?.map(
              (item: any, i: number) =>
                !item.every((valor: any) => valor === undefined) && {
                  [i + 1]: item?.map((it: number, j: number) => ({
                    mes: j + 1,
                    valor: it,
                  })),
                }
            )
            .filter((it: any) => !!it);

          let _consumoMedio = _dados?.consumoMedio;
          let _fatorAjuste = 0;
          let _consumoMedioForaPontaMaisPontaAjustado = 0;

          if (_dados?.tarifaForaPonta && _dados?.tarifaPonta) {
            _fatorAjuste = _dados?.tarifaForaPonta / _dados?.tarifaPonta;
          }

          if (_dados?.consumoMedioPonta && _fatorAjuste && _dados?.consumoMedioForaPonta) {
            _consumoMedioForaPontaMaisPontaAjustado = Number(
              (_dados?.consumoMedioPonta / _fatorAjuste + _dados?.consumoMedioForaPonta)?.toFixed(2)
            );
          }

          if (_grupoAForaPontaMaisPonta) {
            _consumoMedio = _consumoMedioForaPontaMaisPontaAjustado;
          } else if (_grupoAForaPonta) {
            _consumoMedio = _dados?.consumoMedioForaPonta;
          }

          const _dadosUsuario =
            tipo === 'clonar'
              ? {
                  tipoPessoa: _dados.cliente.tipoPessoa,
                  nome: {
                    label: _dados.cliente.nome,
                    value: _dados.codigoCliente,
                  },
                  telefone: _dados.cliente.telefone,
                  cpfCnpj: _dados.cliente.cpfCnpj,
                  razaoSocial: _dados.cliente.razaoSocial,
                  nomeResponsavel: _dados.cliente.nomeResponsavel,
                  email: _dados.cliente.email,
                  dadosCidade: _dados.cliente.cidade
                    ? {
                        label: `${_dados.cliente.cidade}/${_dados.cliente.uf}`,
                        value: _dados.cliente.codigoCidade,
                      }
                    : undefined,
                  vendedor: {
                    label: _vendedor?.nome,
                    value: _vendedor?.codigo,
                  },

                  clonada: true,
                  codigoLead: _dados.codigoLead || codigoLead || geradorDeProposta.clonar?.codigoLead,
                }
              : {
                  codigoLead,
                };

          const _valores = {
            ..._dadosUsuario,

            cidadeProposta: {
              label: `${_dados.cidade} - ${_dados.uf}`,
              value: _dados.codigoCidade,
            },
            concessionaria: _concessionaria,
            consumoMedio: _consumoMedio,
            consumoACompensar: _dados?.consumoTusdg,
            dataInicialPreenchimento: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
            tipoLigacao: tiposLigacao[_dados?.tipoLigacao || 0],

            alturaPainel: _dados?.alturaPainel,
            areaMinima: _dados?.areaMinima,
            aumentoConsumo: _dados?.aumentoConsumo,
            codigoCidade: Number(_dados?.codigoCidade),
            codigoConcessionaria: Number(_dados?.codigoConcessionaria),
            contas: _contas,
            consumoMensal: consumoMensalPayload,
            consumoMinimo: _dados?.consumoMinimo,
            degradacaoSistema: _dados?.degradacaoSistema,
            geracaoSistema: _dados?.geracaoSistema,
            descricao: _dados?.infoAdicionais,
            infoAdicionaisSalvar: false,
            larguraPainel: _dados?.larguraPainel,
            perdaSistema: _dados?.perdaSistema,
            potenciaInstalada: _dados?.potenciaInstalada,
            potenciaInversor: _dados?.potenciaInversor,
            potenciaFieldFornecedores: _dados?.potenciaInversor,

            precoKwh: _dados?.precoKwh,
            simultaneidade: _dados?.fatorSimultaneidade,
            taxaInflacao: _dados?.taxaInflacao,
            validade: gerais?.ultValidadeProposta,
            valorTotal: _dados?.valorTotal,

            //grupo A
            areaMinimaForaPonta: _grupoAForaPonta ? _dados?.areaMinima : undefined,
            areaMinimaForaPontaMaisPonta: _grupoAForaPontaMaisPonta ? _dados?.areaMinima : undefined,
            consumoMedioForaPonta: _dados?.consumoMedioForaPonta,
            consumoMedioPonta: _dados?.consumoMedioPonta,
            consumoMedioForaPontaMaisPontaAjustado: _grupoAForaPontaMaisPonta
              ? _consumoMedioForaPontaMaisPontaAjustado
              : undefined,
            custoDemandaContratada: _dados?.custoDemandaContratada,
            demandaContratada: _dados?.qtdeDemandaContratada,
            fatorAjuste: _grupoAForaPontaMaisPonta ? _dados?.tarifaForaPonta / _dados?.tarifaPonta : undefined,
            geracaoSistemaForaPonta: _grupoAForaPonta ? _dados?.geracaoSistema : undefined,
            geracaoSistemaForaPontaMaisPonta: _grupoAForaPontaMaisPonta ? _dados?.geracaoSistema : undefined,
            potenciaSistemaForaPonta: _grupoAForaPonta ? _dados?.potenciaInstalada : undefined,
            potenciaSistemaForaPontaMaisPonta: _grupoAForaPontaMaisPonta ? _dados?.potenciaInstalada : undefined,
            quantidadePaineisForaPonta: _grupoAForaPonta
              ? _painel[0]?.quantidade || _dados?.quantidadePaineis
              : undefined,
            quantidadePaineisForaPontaMaisPonta: _grupoAForaPontaMaisPonta
              ? _painel[0]?.quantidade || _dados?.quantidadePaineis
              : undefined,
            tarifaDemandaContratada: _dados?.tarifaDemandaContratada,
            tarifaForaPonta: _dados?.tarifaForaPonta,
            tarifaPonta: _dados?.tarifaPonta,
            tarifaTusdg: _dados?.tarifaTusdg,
            tipoDimensionamento: String(_dados?.sistemaDimensionado || 0),
            valorAPagarTusdG: _dados?.valorTusdg,

            //produtos
            inversores: _initValuesInversores,
            outros: _initValuesOutros,
            kitsFotovoltaicos: _initValuesKitsFotovoltaicos,
            kitsDeServicos: _initValuesKitsDeServicos,
            modeloPainel: _painel?.length
              ? {
                  label: _painel[0]?.nome,
                  value: _painel[0]?.codigo,
                }
              : undefined,
            garantiaPaineis: String(_painel[0]?.garantia),
            tipoGarantiaPaineis: _painel[0]?.tipoGarantia,
            potenciaPaineis: String(_painel[0]?.potencia || _dados?.potenciaPaineis),
            quantidadePaineis: String(_painel[0]?.quantidade || _dados?.quantidadePaineis),
            valorVendaPaineis: _painel[0]?.valorVenda ? String(_painel[0]?.valorVenda) : undefined,
            painelAdicionado: Boolean(_painel?.length),
            primeiraRenderizaoClonar: true,

            cor: gerais?.hexCor,
          };

          if (tipo === 'clonar') {
            _setInitialValues(_valores);
          } else {
            const valoresAtuais = methods?.getValues() || {};

            _setInitialValues({
              ...valoresAtuais,
              ..._valores,
            });
          }
        }

        setEtapa(GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO);
        setLoading(false);
      } catch (err) {
        handleError(err);
      }
    },
    // Se adicionar o state de concessionarias, o useEffect entra em loop infinito
    [handleError, setLoading, geradorDePropostaFunctions?.vendedores?.data, setGeradorDeProposta]
  );

  const handleBuscarDadosBaseCalculo = useCallback(async () => {
    try {
      setLoading(true);
      const response = await buscarDadosBaseCalculo();

      return response.data;
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [handleError, setLoading]);

  const handleSetarValores = useCallback(async () => {
    // If para não executar o useEffect enquanto a tela não estiver montada
    if (!isMount || !geradorDePropostaFunctions?.vendedores?.data) {
      return;
    }

    const values = await handleBuscarDadosBaseCalculo();

    _setInitialValues({
      nome: geradorDeProposta.cliente
        ? {
            label: geradorDeProposta.cliente.nome,
            value: geradorDeProposta.cliente.codigo,
          }
        : undefined,
      codigoCliente: geradorDeProposta.cliente?.codigo,
      alturaPainel: values?.data?.alturaPainel || 2,
      aumentoConsumo: values?.data?.aumentoConsumo,
      larguraPainel: values?.data?.larguraPainel || 2,
      perdaSistema: values?.data?.perdaSistema || 15,
      potenciaPaineis: values?.data?.potenciaPaineis || 450,
      dataInicialPreenchimento: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
      degradacaoSistema: 0.8,
      taxaInflacao: 5.79,
      tipoDimensionamento: '0',
      tipoProduto: gerais?.tipoProduto,
      validade: gerais?.ultValidadeProposta,
      vendedor:
        geradorDePropostaFunctions?.vendedores?.data?.length === 1
          ? geradorDePropostaFunctions?.vendedores?.data?.map((item: ListagemVendedoresProposta, index: number) => ({
              label: item.nome,
              value: item.codigo,
              key: index,
            }))[0]
          : geradorDePropostaFunctions?.vendedores?.data
              ?.filter((it) => it.codigoUsuario === profile?.sub)
              ?.map((item: ListagemVendedoresProposta, index: number) => ({
                label: item.nome,
                value: item.codigo,
                key: index,
              }))[0] || undefined,
      cor: gerais?.hexCor,
      descricao: gerais?.ultInfoAdicional,
      infoAdicionaisSalvar: false,
    });
  }, [isMount, handleBuscarDadosBaseCalculo, geradorDePropostaFunctions?.vendedores?.data, profile?.sub]);

  const handleBuscarVendedores = useCallback(async () => {
    if (!geradorDePropostaFunctions?.vendedores?.data) {
      const { data } = await listarVendedoresPropostas();

      setGeradorDePropostaFunctions({ type: 'vendedores', vendedoresPayload: data });
    }
  }, [geradorDePropostaFunctions?.vendedores?.data]);

  useEffect(() => {
    if (geradorDeProposta?.clonar?.codigo) {
      _handleBuscarDados({
        codigoProposta: geradorDeProposta?.clonar?.codigo,
        tipo: 'clonar',
      });
    } else {
      handleSetarValores();
    }
  }, [_handleBuscarDados, geradorDeProposta?.clonar?.codigo, handleSetarValores]);

  useEffect(() => {
    handleBuscarVendedores();
  }, [handleBuscarVendedores]);

  return {
    initialValues,
    handleClose,
    handleControleGerador,
    handleProximoESubmit,
    handleVoltar,
  };
};

export default useGeradorDePropostaFunctions;
