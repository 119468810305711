import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const useInformacoesAdicionaisDialog = () => {
  const { setValue, watch } = useFormContext();
  const { setGeradorDeProposta } = useGeradorDeProposta();

  const _formValues = watch();

  const initialValuesInformacoesAdicionaisDialog = useMemo(
    () => ({
      descricao: _formValues.descricao,
      infoAdicionaisSalvar: _formValues.infoAdicionaisSalvar,
    }),
    [_formValues.descricao, _formValues.infoAdicionaisSalvar]
  );

  const handleClose = useCallback(() => {
    setGeradorDeProposta({ informacoesAdicionaisDialog: false });
  }, [setGeradorDeProposta]);

  const handleSubmitInformacoesAdicionaisDialog = useCallback(
    (values: any) => {

      const descricao = values.descricao?.trim();
      const salvarNovaDescricao = values.infoAdicionaisSalvar|| false;
      
      if (salvarNovaDescricao) {
        useConfiguracoes.setState({gerais: {ultInfoAdicional: descricao,}})
      }

      setValue('descricao', descricao);
      setValue('infoAdicionaisSalvar', salvarNovaDescricao);
      setGeradorDeProposta({ informacoesAdicionaisDialog: false });
    },
    [setValue, setGeradorDeProposta]
  );

  return { initialValuesInformacoesAdicionaisDialog, handleSubmitInformacoesAdicionaisDialog, handleClose };
};

export default useInformacoesAdicionaisDialog;
